@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/grid";
@import url("https://use.typekit.net/vnb0bgv.css");

// Colours
$black: #000;
$white: #FFF;
$navy_blue: #000631;


//Menu
$menuHeight: 40px;

//Viewport
$viewableArea: calc(100vh - #{$menuHeight});
$mobileViewableArea: calc(100vh - 51px);

// Colour Roles
$primary-color: $navy_blue;
$secondary-color: $white;

// Screen Sizes
$screen-xl: map-get($grid-breakpoints, xl); // ~1200px
$screen-lg: map-get($grid-breakpoints, lg); // ~992px
$screen-md: map-get($grid-breakpoints, md); // ~768px
$screen-sm: map-get($grid-breakpoints, sm); // ~576px
$screen-xs: map-get($grid-breakpoints, xs); // 0

// Screen Sizes Max
$screen-xl-max: $screen-xl - 1;
$screen-lg-max: $screen-lg - 1;
$screen-md-max: $screen-md - 1;
$screen-sm-max: $screen-sm - 1;

//content width
$page-max:1660px;
$content-max: 1260px;
$padding-text: 12%;
$padding-components-lg: 60px;
$padding-components-sm: 40px;
$after-components-lg: 100px;
$after-components-sm: 70px;

// Sizes
$font-size-base: 1.6rem;

$font-size-initial: 5.8rem; //58px;
$font-size-h1-lg: 4.3rem; // 44px
$font-size-h1-sm: 2.8rem; // 44px
$font-size-h2-lg: 3.1rem; // 36px
$font-size-h2-sm: 2rem; // 36px
$font-size-h3-lg: 2.5rem; // 30px
$font-size-h3-sm: 1rem; // 30px
$font-size-h4: 2.8rem; // 28px
$font-size-h5: 2.6rem; // 26px
$font-size-h6: 2.4rem; // 24px
$font-size-h7: 2rem; // 20px
$font-size-h8: 1.8rem; // 18px

$font-size-p-lg: 1.7rem;
$font-size-p-sm: 1.4rem;
$font-size-form-lg: 2rem;
$font-size-form-sm: 1.3rem;


$font-size-subtitle: 1.5rem; // 15px
$font-size-ads: 1.2rem; //12px


// Letter spacings
$font-size-h1-word-spacing: 6rem;
$font-size-h4-letter-spacing: .1rem;


// Fonts


$font-helvetica:'Helvetica Neue, Helvetica, Arial, sans-serif';
$font-utopia: utopia-std, serif;
$font-effra: effra, sans-serif;

$weight-headings: 700;
$weight-content: 400;

$weight-extra-light: 100;
$weight-light: 300;
$weight-normal: 400;
$weight-bold: 600;
$weight-extra-bold: 800;

$primary-font-family: $font-utopia;
$primary-font-size: 1.6rem;
$primary-weight: $weight-normal;
$primary-letter-spacing: 0rem;
$primary-word-spacing:  0rem;